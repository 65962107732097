import React from "react";
import {FormattedMessage, useIntl} from "gatsby-plugin-intl";


const HowToGet = () => {

    const intl = useIntl();

    return (

        <section id="howtoget">
            <div className="py-16 mx-auto max-w-7xl px-4 lg:py-20">

                <div className="flex flex-col justify-center items-center mb-6">
                    <h2 className="mb-2 text-2xl font-medium tracking-tight leading-10 color-title ">




                        <FormattedMessage id="howto.title" />


                        <span className="color-primary">{" "}Polenium?</span>

                    </h2>
                    <p className="text-base color-desc">

                        <FormattedMessage id="howto.desc" />


                    </p>
                </div>

                <div className="flex lg:flex-row  flex-col  lg:space-x-4 md:space-y-4 lg:space-y-0">



                    <div className="xl:w-1/2 lg:w-1/2 md:w-full">
                        <div className="border-4 border-gray-200 rounded-lg px-8 py-6">

                            <h2 className="text-lg sm:text-xl color-title font-medium mb-2">
                                <FormattedMessage id="howto.steponetitle" />

                            </h2>
                            <p className="leading-relaxed color-desc text-base mb-4">
                                <FormattedMessage id="howto.steponedesc" />


                               </p>
                            <p className="color-primary font-bold inline-flex tracking-tight text-sm items-center">
                                #Step 1
                            </p>
                      </div>
                    </div>

                    <div className="xl:w-1/2 lg:w-1/2 md:w-full">
                        <div className="border-4 border-gray-200 rounded-lg px-8 py-6">

                            <h2 className="text-lg sm:text-xl color-title font-medium mb-2">
                                <FormattedMessage id="howto.steptwotitle" />


                            </h2>
                            <p className="leading-relaxed color-desc text-base mb-4">

                                <FormattedMessage id="howto.steptwodesc" />

                            </p>
                            <p className="color-primary font-bold inline-flex tracking-tight text-sm items-center">

                                #Step 2

                            </p>
                        </div>
                    </div>


                </div>

                <div className="flex lg:flex-row  flex-col  lg:space-x-4 md:space-y-4 lg:space-y-0 mt-5">



                    <div className="xl:w-1/2 lg:w-1/2 md:w-full">
                        <div className="border-4 border-gray-200 rounded-lg px-8 py-6">

                            <h2 className="text-lg sm:text-xl color-title font-medium mb-2">

                                <FormattedMessage id="howto.stepthreetitle" />


                            </h2>
                            <p className="leading-relaxed color-desc text-base mb-4">

                                <FormattedMessage id="howto.stepthreedesc" />


                            </p>
                            <p className="color-primary font-bold inline-flex tracking-tight text-sm items-center">
                                #Step 3

                            </p>
                        </div>
                    </div>

                    <div className="xl:w-1/2 lg:w-1/2 md:w-full">
                        <div className="border-4 border-gray-200 rounded-lg px-8 py-6">

                            <h2 className="text-lg sm:text-xl color-title font-medium mb-2">

                                <FormattedMessage id="howto.stepfourtitle" />


                            </h2>
                            <p className="leading-relaxed color-desc text-base mb-4">

                                <FormattedMessage id="howto.stepfourdesc" />


                            </p>
                            <p className="color-primary font-bold inline-flex tracking-tight text-sm items-center">

                                #Step 4

                            </p>
                        </div>
                    </div>


                </div>

            </div>
        </section>

    )
}

export default HowToGet;