import React from "react";
import {FormattedMessage} from "gatsby-plugin-intl";

const WhitePaper = () => {

    return (

            <div className="py-16 mx-auto max-w-7xl">
                <div className="px-4">
                    <div className="flex flex-col lg:flex-row">
                        <div className="lg:mb-0 lg:w-1/2 lg:pr-5">
                            <h2 className="font-sans text-2xl font-bold tracking-tight color-title lg:text-4xl ">
                                <FormattedMessage id="wp.moreinfo" />

                                {' '}<br/>
                                <span className="inline-block color-third">
                                 Polenium
                                </span>
                                {" "}
                                <FormattedMessage id="wp.moreinfocon" />

                            </h2>
                        </div>
                        <div className="lg:w-1/2">
                            <p className="mb-4 text-base color-desc">

                                <FormattedMessage id="wp.wpdesc" />
                            </p>
                            <a
                                href="/polenium-whitepaper.pdf"
                                aria-label=""
                                target="_blank"
                                className="inline-flex items-center font-semibold transition-colors duration-200 text-blue-600 hover:text-deep-purple-800"
                            >

                                <FormattedMessage id="wp.btn" />

                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default WhitePaper;