import React from "react";
 import {FormattedMessage, useIntl} from "gatsby-plugin-intl";


const CallToAction = () => {

    const intl = useIntl();

    return (
        <section className="py-6 cta-bg">
            <div className="max-w-7xl py-8 mx-auto lg:py-12 flex flex-col justify-center text-center">
                <div className="flex flex-col justify-center lg:text-center max-w-md mx-auto">
                    <p className="mb-1 text-sm font-medium tracking-normal text-black">

                        <FormattedMessage id="cta.desc" />

                    </p>
                    <h1 className="py-2 text-4xl font-bold leading-tight text-black">
                        <FormattedMessage id="cta.title" />
                    </h1>
                </div>
                <div className="flex flex-col items-center justify-center flex-shrink-0 mt-10 space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-center">


                    <a href="https://pancakeswap.finance/swap?outputCurrency=0xdd48af3e1d6f5c3c0963370d595a36dc5e6ea4e2" className="inline-flex btn-primary items-center">

                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                        <span className="flex flex-col items-start ml-4 leading-none">
					<span className="mb-1 text-xs">  <FormattedMessage id="cta.getpolen" /></span>
					<span className="font-semibold title-font">  <FormattedMessage id="cta.swap" /></span>
				</span>
                    </a>


                    <a href="https://pancakeswap.finance/add/0xdd48Af3e1D6f5C3c0963370d595A36Dc5e6EA4e2/BNB" className="inline-flex btn-primary items-center">


                        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
                        </svg>
                        <span className="flex flex-col items-start ml-4 leading-none">
					<span className="mb-1 text-xs">  <FormattedMessage id="cta.addliqtitle" /></span>
					<span className="font-semibold title-font">  <FormattedMessage id="cta.addliq" /></span>
				</span>
                    </a>

                </div>

                <div className="flex flex-col items-center justify-center mt-2">
                <h1 className="py-5 lg:text-xl font-bold leading-tight text-black max-w-7xl sm:text-sm text-xs xl:text-xl md:text-base">
                    <span className="text-lg text-gray-500">Contract Address: </span>
                    <FormattedMessage id="cta.contract" />
                </h1>
                </div>

            </div>
        </section>
    )

}

export default CallToAction;