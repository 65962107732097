import React from "react"

import Hero from "./hero";
import Header from "./header";
import Content from "./content";
import Footer from "./footer";
import Feature from "./feature";
import Steps from "./steps";
import CallToAction from "./cta";
import WhitePaper from "./whitePaper";
import HowToGet from "./howToGet";

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Economy from "./economy";
import DxSale from "./dxsale";




const Layout = ({intl}) => {

    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <div className="bg-polen">

               <Header siteTitle={intl.formatMessage({ id: "title" })}/>

                <Hero/>


                {/*<DxSale />*/}


                <Content/>


                <WhitePaper/>

                <Steps/>

                <Economy />

                <Feature/>

                <HowToGet/>

                <CallToAction/>

                <Footer/>
            </div>
        </>
    )
};



export default injectIntl(Layout);