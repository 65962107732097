import React from "react";
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"



function Hero({intl}) {

    return (

            <div className="max-w-7xl m-auto justify-center py-12">
                <div className="md:py-12 m-auto lg:w-2/3 w-full items-center justify-center">


                    <div className="text-black">
                        <div className="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                            <h2 className="text-4xl font-bold text-black tracking-wide leading-10 ">

                                {intl.formatMessage({ id: "hero.welcome" })}
                                {/*<FormattedMessage id="hero.welcome" />*/}


                            </h2>

                            <p className="text-lg font-medium text-black mt-5 px-8 lg:px-24">

                                {intl.formatMessage({ id: "hero.desc" })}

                                {/*<FormattedMessage id="hero.desc" />*/}


                            </p>
                            <div className="lg:mt-0 lg:flex-shrink-0">
                                <div className="mt-12 inline-flex rounded-md space-x-8">
                                    <a
                                            href="#about"
                                            className="px-6 py-4 bg-black text-base text-white rounded-[40px] font-bold transition ease-in duration-200 shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2">
                                        {/*<FormattedMessage id="hero.discover" />*/}
                                        {intl.formatMessage({ id: "hero.discover" })}


                                    </a>
                                    <a
                                        href="#howtoget"
                                            className="px-6 py-4 flex text-black text-center bg-white shadow-lg rounded-[40px] text-base font-semibold ">
                                        {/*<FormattedMessage id="hero.buytoken" />*/}
                                        {intl.formatMessage({ id: "hero.buytoken" })}


                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>


    )
}

export default injectIntl(Hero)