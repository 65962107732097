import React from "react";
import {FormattedMessage, useIntl} from "gatsby-plugin-intl";
import PropTypes from "prop-types"

const Economy = () => {

        const intl = useIntl();
    return (



        <section id="economy">



            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">

                    <div className="flex flex-col mb-6">
                            <h2 className="mb-2 text-2xl font-medium tracking-tight leading-10 color-title ">

                                    <span className="color-primary">{" "}Polenium </span>

                                    <FormattedMessage id="eco.tokeneconomy" />


                            </h2>
                            <p className="text-base color-desc">

                                    <FormattedMessage id="eco.tokeneconomydesc" />



                            </p>
                    </div>
            <div className="grid gap-24 row-gap-8 lg:grid-cols-5">
            <div className="grid gap-8 lg:col-span-2">
            <div>
            <p className="mb-2 text-lg font-bold">
                    <FormattedMessage id="eco.devtitle" />

            </p>
            <p className="text-gray-700">
                    <FormattedMessage id="eco.devdesc" />


            </p>
            </div>
            <div>
            <p className="mb-2 text-lg font-bold">
                    <FormattedMessage id="eco.marketingtitle" />

            </p>
            <p className="text-gray-700">

                    <FormattedMessage id="eco.marketingdesc" />




            </p>
            </div>

                    <div>
                            <p className="mb-2 text-lg font-bold">
                                    <FormattedMessage id="eco.liquitytitle" />

                            </p>
                            <p className="text-gray-700">
                                    <FormattedMessage id="eco.liquitydesc" />





                            </p>
                    </div>
            </div>
            <div className="grid border divide-y rounded lg:col-span-3 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
            <div className="flex flex-col justify-between p-10">
            <div>
            <p className="text-lg font-semibold text-gray-800 sm:text-base">
            Polenium
            </p>
            <p className="text-2xl font-bold text-deep-purple-accent-400 sm:text-xl">
            POLEN
            </p>
            </div>
            <div>
            <p className="text-lg font-semibold text-gray-800 sm:text-base">
             Liquidity
            </p>
            <p className="text-2xl font-bold text-deep-purple-accent-400 sm:text-xl">
            - -
            </p>
            </div>
            <div>
            <p className="text-lg font-semibold text-gray-800 sm:text-base">
            Burned
            </p>
            <p className="text-2xl font-bold text-deep-purple-accent-400 sm:text-xl">
             - -
            </p>
            </div>
            </div>
            <div className="flex flex-col justify-between p-10">
            <div>
            <p className="text-lg font-semibold text-gray-800 sm:text-base">
            Holders
            </p>
            <p className="text-2xl font-bold text-deep-purple-accent-400 sm:text-xl">
             - -
            </p>
            </div>
            <div>
            <p className="text-lg font-semibold text-gray-800 sm:text-base">
            Total Supply
            </p>
            <p className="text-2xl font-bold text-deep-purple-accent-400 sm:text-xl">
            1.2 Trillion
            </p>
            </div>
            <div>
            <p className="text-lg font-semibold text-gray-800 sm:text-base">
            Contract
            </p>
            <p className="text-2xl font-bold text-deep-purple-accent-400 sm:text-xl">
            Binance Smart Chain
            </p>
            </div>
            </div>
            </div>
            </div>

            </div>

        </section>

    );
};

export default Economy;