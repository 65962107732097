import React from "react";
import {FormattedMessage} from "gatsby-plugin-intl";

const Steps = () => {

    return(
        <section id="roadmap" className="text-gray-600 body-font">
            <div className="max-w-7xl px-4 py-24 mx-auto flex">
                <div className="flex flex-col w-full">


                        <div className="flex relative pb-12">
                            <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                                <div className="h-full w-1 bg-gray-200 pointer-events-none"/>
                            </div>
                            <div
                                className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-100 inline-flex items-center justify-center text-white relative z-10">

                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 color-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                                </svg>
                            </div>
                            <div className="flex-grow pl-4">
                                <h2 className="font-medium text-2xl color-title mb-1 tracking-wider">
                                    <FormattedMessage id="steps.adim1" />
                                    </h2>
                                <p className="leading-relaxed text-base">
                                    <FormattedMessage id="steps.adim1desc" />


                                </p>
                            </div>
                        </div>

                    <div className="flex relative pb-12">
                        <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-200 pointer-events-none"/>
                        </div>
                        <div
                            className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-100 inline-flex items-center justify-center text-white relative z-10">


                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 color-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg>
                        </div>
                        <div className="flex-grow pl-4">
                            <h2 className="font-medium text-2xl color-title mb-1 tracking-wider"><FormattedMessage id="steps.adim2" /></h2>
                            <p className="leading-relaxed text-base">

                                <FormattedMessage id="steps.adim2desc" />



                            </p>
                        </div>
                    </div>


                    <div className="flex relative pb-12">
                        <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                            <div className="h-full w-1 bg-gray-200 pointer-events-none"/>
                        </div>
                        <div
                            className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-100 inline-flex items-center justify-center text-white relative z-10">

                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 color-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                            </svg>
                        </div>

                        <div className="flex-grow pl-4">
                            <h2 className="font-medium text-2xl color-title mb-1 tracking-wider"><FormattedMessage id="steps.adim3" /></h2>
                            <p className="leading-relaxed text-base">

                                 <FormattedMessage id="steps.adim3desc" />


                            </p>
                        </div>

                    </div>

                    <div className="flex relative pb-12">
                    <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-1 bg-gray-200 pointer-events-none"/>
                    </div>
                    <div
                        className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-100 inline-flex items-center justify-center text-white relative z-10">
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                             strokeWidth="2" className="w-5 h-5 color-secondary" viewBox="0 0 24 24">
                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"/>
                        </svg>
                    </div>
                    <div className="flex-grow pl-4">
                        <h2 className="font-medium text-2xl color-title mb-1 tracking-wider"><FormattedMessage id="steps.adim4" /></h2>
                        <p className="leading-relaxed text-base">

                            <FormattedMessage id="steps.adim4desc" />


                        </p>
                    </div>
                </div>


                    <div className="flex relative pb-12">
                        <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                            {/*<div className="h-full w-1 bg-gray-200 pointer-events-none"/>*/}
                        </div>
                        <div
                            className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-100 inline-flex items-center justify-center text-white relative z-10">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 color-secondary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                            </svg>
                        </div>
                        <div className="flex-grow pl-4">
                            <h2 className="font-medium text-2xl color-title mb-1 tracking-wider">
                                <FormattedMessage id="steps.morestep" />

                            </h2>
                            <p className="leading-relaxed text-base">
                                <FormattedMessage id="steps.moredesc" />
                            </p>
                        </div>
                    </div>





                </div>
            </div>
        </section>
    )
}


export default Steps;