import React from "react";
import {FormattedMessage, useIntl} from "gatsby-plugin-intl";
import PropTypes from "prop-types"

const Content = () => {

    const intl = useIntl();
    return (
        <section id="about" className="text-white body-font pb-24">
            <div className="max-w-7xl py-5 mx-auto">

                <div className="flex flex-row md:flex-nowrap flex-wrap space-y-4 md:space-y-0 md:space-x-4">


                    <div className="xl:w-1/3 w-full">
                        <div className="ring-inset ring-8 ring-indigo-100 p-6 py-12 rounded-[40px]">
                            <div
                                className="w-30 h-30 flex items-center justify-center rounded-full mb-4">


                                <img src="icon/external-drive.png" alt="" className="" />


                            </div>
                            <div className="flex flex-col items-center justify-center">
                            <h2 className="text-lg color-title font-medium mb-2">
                                <FormattedMessage id="content.dappbrowser" />
                            </h2>
                            <p className="leading-relaxed text-center text-base color-desc">
                                <FormattedMessage id="content.dappdesc" />

                            </p>
                            </div>
                        </div>
                    </div>


                    <div className="xl:w-1/3 w-full">
                        <div className="ring-inset ring-8 ring-blue-100 p-6 py-12 rounded-[40px]">
                            <div
                                className="w-30 h-30 flex items-center justify-center rounded-full mb-4">


                                <img src="icon/cpu-setting.png" alt="" className="" />


                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <h2 className="text-lg color-title font-medium mb-2">
                                    <FormattedMessage id="content.dusukenerji" /> </h2>
                                <p className="leading-relaxed text-center text-base color-desc">
                                    <FormattedMessage id="content.dusukdesc" />

                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="xl:w-1/3 w-full">
                        <div className="ring-inset ring-8 ring-green-100 p-6 py-12 rounded-[40px]">
                            <div
                                className="w-30 h-30 flex items-center justify-center rounded-full mb-4">


                                <img src="icon/driver.png" alt="" className="" />


                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <h2 className="text-lg color-title font-medium mb-2">

                                    <FormattedMessage id="content.pazaryeri"/></h2>
                                <p className="leading-relaxed text-center text-base color-desc">
                                    <FormattedMessage id="content.pazeryeridesc" />

                                </p>
                            </div>
                        </div>
                    </div>






                </div>

            </div>
        </section>
    )
}

export default Content;