import React from "react";
import {FormattedMessage, useIntl} from "gatsby-plugin-intl";


const Feature = () => {

    const intl = useIntl();

    return (

        <section id="polen">
            <div className="py-16 px-4 mx-auto max-w-7xl lg:py-20">
                <div className="flex lg:flex-row flex-col">
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex flex-col mb-6">
                            <h2 className="mb-6 font-sans text-2xl font-medium tracking-tight leading-10 color-title ">
                                <FormattedMessage id="feature.title" />

                                <span className="color-third">{" "}
                                    <FormattedMessage id="feature.titlecolored" />
                                    </span>

                            </h2>
                            <p className="text-base color-desc">
                                <FormattedMessage id="feature.desc" />

                            </p>
                        </div>
                        <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col  lg:space-x-4 md:lg:space-x-4 sm:lg:space-x-0 sm:space-y-4 space-y-4 lg:space-y-0 md:space-y-0">
                            <div className="md:w-1/2 w-full	lg:w-1/2 bg-blue-100 shadow-sm rounded-lg">
                                <div className="h-full p-8 ">
                                    <h6 className="mb-2 color-secondary font-semibold leading-5">
                                        Implementations that will provide continuous income growth.
                                    </h6>
                                    <p className="text-sm color-desc">
                                        All the income we will generate through the applications will be used for capital increase.
                                    </p>
                                </div>
                            </div>
                            <div className="md:w-1/2 w-full	lg:w-1/2 bg-blue-100 shadow-sm rounded-lg">
                                <div className="h-full p-8 ">
                                    <h6 className="mb-2 color-secondary font-semibold leading-5">
                                        Polenium will be shaped by the advice and opinions of its investors.

                                    </h6>
                                    <p className="text-sm color-desc">
                                        Therefore, with the help of community channels, only those who have Polenium Token can comment on the future of the token.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full justify-center lg:justify-end pl-0 lg:pl-2 lg:mt-0 mt-2">
                        <img
                            className="object-cover h-96"
                            src="/img/polenium-polen-desc.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Feature;