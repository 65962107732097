import React from "react";

const Footer = () => {
    return (


        <footer className="text-gray-600 body-font">
            <div className="max-w-7xl py-8 mx-auto flex items-center sm:flex-row flex-col">

                <div className="flex justify-start lg:w-0 lg:flex-1">
                <a href="#" className="flex items-center">
                   <img src="img/polenlogo-1.png" className="w-42" />
                </a>



                </div>


                <div className="flex justify-center lg:w-0 lg:flex-1">
                <span className="font-medium items-center justify-center color-desc">
                    © 2021 Polenium. All rights reserved
                </span>
                </div>



                <div className="flex justify-start lg:w-0 lg:flex-1">
                <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center">
      <a href="mailto:polen@polenium.com" className="text-gray-500">
       <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
</svg>
      </a>
      <a href="https://twitter.com/poleniumtoken" className="ml-3 text-gray-500">
        <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5"
             viewBox="0 0 24 24">
          <path
              d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
        </svg>
      </a>
      <a href="https://instagram.com/poleniumtoken" className="ml-3 text-gray-500">
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
             className="w-5 h-5" viewBox="0 0 24 24">
          <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
          <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
        </svg>
      </a>
      <a href="https://t.me/poleniumtoken" className="ml-3 text-gray-500">
       <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
</svg>
      </a>
    </span>
                </div>
            </div>
        </footer>
    )

}

export default Footer;