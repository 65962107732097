import React, {Fragment} from "react";
import {Popover, Transition} from "@headlessui/react";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import scrollTo from 'gatsby-plugin-smoothscroll';



import {
    MenuIcon,
    XIcon,
} from '@heroicons/react/outline'
import SEO from "./seo";
import Layout from "./layout";





const Header = ({ siteTitle }) => {


    const intl = useIntl();


    return (
        <header>

            <SEO
                lang={intl.locale}
                title={intl.formatMessage({ id: "title" })}
                keywords={[`gatsby`, `application`, `react`]}
            />




            <Popover className="relative bg-transparent">
                {({ open }) => (
                    <>
                        <div className="max-w-7xl px-4 mx-auto">
                            <div className="flex items-center border-0 border-gray-100 py-6 space-x-8 justify-between">
                                <div className="flex justify-start">
                                    <a href="#">
                                        <span className="sr-only">Polenium</span>
                                        <img
                                            className="logo"
                                            src="img/polenlogo-1.png"
                                            alt=""
                                        />
                                    </a>
                                </div>
                                <div className="-mr-2 -my-2 md:hidden">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">
                                               <FormattedMessage id="header.closemenu" />
                                        </span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                                <Popover.Group as="nav" className="hidden md:flex space-x-10 ml-0 justify-center tracking-tight">


                                    <button onClick={() => scrollTo('#about')} className="focus:outline-none text-base font-medium color-head hover:color-primary">


                                        <FormattedMessage id="mainmenu.about" />

                                    </button>

                                    <button onClick={() => scrollTo('#roadmap')} className="focus:outline-none text-base font-medium color-head hover:color-primary">
                                        <FormattedMessage id="mainmenu.roadmap" />

                                    </button>

                                    <button onClick={() => scrollTo('#economy')} className="focus:outline-none text-base font-medium color-head hover:color-primary">

                                        <FormattedMessage id="mainmenu.economy" />

                                    </button>

                                    <button onClick={() => scrollTo('#polen')} className="focus:outline-none text-base font-medium color-head hover:color-primary">
                                        <FormattedMessage id="mainmenu.polen" />

                                    </button>



                                </Popover.Group>
                                <div className="hidden md:flex items-center justify-end">




                                    <a
                                        href="https://pancakeswap.finance/add/0xdd48Af3e1D6f5C3c0963370d595A36Dc5e6EA4e2/BNB"
                                        className="ml-2 whitespace-nowrap inline-flex items-center justify-center btn-primary"
                                    >

                                        <FormattedMessage id="header.getpolen" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <Transition
                            show={open}
                            as={Fragment}
                            enter="duration-200 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel
                                focus
                                static
                                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                            >
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                    <div className="pt-5 pb-6 px-5">
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <img
                                                    className="h-8 w-auto"
                                                    src="img/polenlogo-1.png"
                                                    alt="Polenium"
                                                />

                                            </div>
                                            <div className="-mr-2">
                                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                    <span className="sr-only">Close menu</span>
                                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                                </Popover.Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="py-6 px-5 space-y-6">
                                        <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                            <a href="#about" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                <FormattedMessage id="mainmenu.about" />
                                            </a>

                                            <a href="#roadmap" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                <FormattedMessage id="mainmenu.roadmap" />
                                            </a>

                                            <a href="#economy" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                <FormattedMessage id="mainmenu.economy" />
                                            </a>

                                            <a href="#polen" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                <FormattedMessage id="mainmenu.polen" />
                                            </a>

                                        </div>
                                        <div>
                                            <a
                                                href="#about"
                                                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-black"
                                            >


                                                <FormattedMessage id="mainmenu.discover" />

                                            </a>
                                            <p className="mt-6 text-center text-base font-medium text-gray-500">
                                                <a href="https://pancakeswap.finance/add/0xdd48Af3e1D6f5C3c0963370d595A36Dc5e6EA4e2/BNB" className="text-black hover:text-indigo-600">

                                                    <FormattedMessage id="mainmenu.getpolen" />

                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>

        </header>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header;
